import { Dialog } from '@headlessui/react';
import { CSSProperties, ReactNode, StyleHTMLAttributes } from 'react';

export type ModalProps = {
    open: boolean;
    onClose: () => any;
    title?: ReactNode;
    children?: ReactNode;
    icon?: ReactNode;
    OverlayStyle?: CSSProperties;
    buttons?: [
        {
            component: ReactNode;
        },
    ];
};

const OverlayStyle: CSSProperties = {
    position: 'fixed',
    opacity: 0.3,
    background: 'black',
};

const ModalStyle: CSSProperties = {
    padding: '60px',
    width: 540,

    position: 'relative',
};

const ModalRootStyle: CSSProperties = {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const AthenaModal = (props: ModalProps) => (
    <Dialog
        className="fixed inset-0 z-30 overflow-y-auto"
        style={ModalRootStyle}
        open={props.open}
        onClose={props.onClose}
    >
        <Dialog.Overlay
            className="fixed inset-0 bg-themeBlack opacity-30"
            style={{ ...OverlayStyle, ...props.OverlayStyle }}
        />
        <div
            className="font-mont relative bg-white rounded-2xl mx-auto pa h-auto lg:h-[430px]"
            style={ModalStyle}
        >
            {props.icon}
            {props.title && <Dialog.Title>{props.title}</Dialog.Title>}

            {props.children}

            {(props.buttons || []).map((btn) => btn.component)}
        </div>
    </Dialog>
);

export default AthenaModal;

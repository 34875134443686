import clsx from 'clsx';

const Field = ({ children, className }: any) => (
    <div
        className={clsx(
            `field pb-5 md:pb-0 w-full md:w-1/2 text-sm`,
            className,
        )}
    >
        {children}
    </div>
);

export default Field;

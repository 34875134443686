import { useContext } from 'react';
import Link from 'next/link';
import { KycStatus, KycStatusContext } from 'src/contexts/kycStatusContext';

import KycDialogIcon from 'assets/images/kycSubheaderIcon.svg';
import PrimaryButton from '../common/buttons/PrimaryButton';
import { AccountContext } from 'src/contexts/accountContext';
import { TAccount } from 'src/services/useAccount';
import { Country } from 'src/libs/types/country';

const getKycReminderData = (
    kycData: { [key: string]: any },
    accountData: TAccount,
) => {
    if (kycData.status === KycStatus.LOADING) return null;
    if (kycData.shouldHideFirstTimeDialog) return null;
    if (kycData.status === KycStatus.FAILED_VERIFICATION) {
        return {
            statusTitle:
                'Your KYC form was not accepted, please check your information',
            buttonLabel: 'Update details',
            statusColor:
                'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FA383E',
            textColor: '#FA383E',
            buttonColor: '#FA383E',
            href: '/kyc',
        };
    }
    if (
        kycData?.status === KycStatus.VERIFIED &&
        !accountData?.stripeId &&
        kycData?.country === Country.Canada
    ) {
        return {
            statusTitle: 'Please provide payment details',
            buttonLabel: 'Go to Stripe',
            statusColor:
                'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, #1F2E56, #1F2E56)',
            textColor: '#000000',
            buttonColor: '#1F2E56',
            href: '/balance',
        };
    }
    if ([KycStatus.UNVERIFIED, KycStatus.NO_DATA].includes(kycData.status)) {
        return {
            statusTitle: 'Provide KYC details',
            buttonLabel: 'Go to KYC process',
            statusColor:
                'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, #1F2E56, #1F2E56)',
            textColor: '#000000',
            buttonColor: '#1F2E56',
            href: '/kyc',
        };
    }
    return null;
};

const KycReminder = () => {
    const { kycData } = useContext(KycStatusContext);
    const { accountData } = useContext(AccountContext);
    const config = getKycReminderData(kycData, accountData);

    if (!config) return null;

    return (
        <div
            className="sticky top-[64px] w-full flex justify-between items-center px-4 md:px-[30px] py-4 flex-col md:flex-row z-10"
            style={{
                background: config.statusColor,
            }}
        >
            <div className="flex md:mr-auto ">
                <KycDialogIcon fill={config.buttonColor} />

                <div
                    className="font-semibold text-lg pl-3 md:pl-[15px] flex items-center"
                    style={{ color: config.textColor }}
                >
                    {config.statusTitle}
                </div>
            </div>
            {config.href ? (
                <Link href={config.href} passHref={true}>
                    <PrimaryButton
                        label={config.buttonLabel}
                        className="h-[30px]"
                        paddingX="px-[24px]"
                        paddingY="py-[4px]"
                        margin="mt-2 md:mt-0 md:ml-auto"
                        style={{ backgroundColor: config.buttonColor }}
                    />
                </Link>
            ) : (
                <PrimaryButton
                    label={config.buttonLabel}
                    className="h-[30px]"
                    paddingX="px-[24px]"
                    paddingY="py-[4px]"
                    margin="mt-2 md:mt-0 md:ml-auto"
                    style={{ backgroundColor: config.buttonColor }}
                />
            )}
        </div>
    );
};

export default KycReminder;

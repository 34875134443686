import clsx from 'clsx';
import { ReactNode } from 'react';
import OutlinedButton from '../buttons/OutlinedButton';
import PrimaryButton from '../buttons/PrimaryButton';
import GeneralModal from './GeneralModal';

type TProps = {
    icon: ReactNode;
    title: string;
    description: string | JSX.Element;
    outlinedButton?: {
        label: string;
        handler: () => void;
        className?: string;
    };
    primaryButton: {
        label: string;
        handler: () => void;
        className?: string;
    };
    open: boolean;
    onClose: () => void;
};

const ModalDialog = ({
    icon,
    title,
    description,
    primaryButton,
    outlinedButton,
    open,
    onClose,
}: TProps) => {
    return (
        <GeneralModal
            open={open}
            onClose={onClose}
            OverlayStyle={{ opacity: 0.7 }}
        >
            <div className="flex items-center flex-col w-50 h-full">
                <div>{icon}</div>

                <div className="flex justify-center py-[30px] text-2xl font-bold text-center">
                    {title}
                </div>

                <div className="flex justify-around text-center pb-[30px] text-sm text-black/60 ">
                    {description}
                </div>

                <div
                    className={clsx(
                        'flex flex-col-reverse lg:flex-row lg:justify-between mt-auto w-full',
                        outlinedButton
                            ? 'lg:justify-between'
                            : 'items-center lg:justify-around',
                    )}
                >
                    {outlinedButton && (
                        <OutlinedButton
                            label={outlinedButton.label}
                            onClick={outlinedButton.handler}
                            margin="m-0"
                            className={clsx(
                                'w-full lg:w-[180px] py-3',
                                primaryButton.className,
                            )}
                        />
                    )}
                    <PrimaryButton
                        label={primaryButton.label}
                        onClick={primaryButton.handler}
                        margin="m-0"
                        className={clsx(
                            'w-full lg:w-[180px] py-3 mb-3 lg:mb-0',
                            primaryButton.className,
                        )}
                    />
                </div>
            </div>
        </GeneralModal>
    );
};

export default ModalDialog;

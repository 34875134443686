import clsx from 'clsx';
import { useContext, useState } from 'react';
import { MenuAlt2Icon } from '@heroicons/react/outline';
import SideDialogWrapper from 'src/components/common/modals/SideDialogWrapper';
import { SideBar } from 'src/components/layout/Sidebar';
import { BarMenu } from 'src/components/layout/BarMenu';
import KycStatusFirstTimeDialog, {
    KycStatusDialog,
} from '../kyc/KycStatusDialog';
import CreateMyReferralView from 'src/components/pages/myReferrals/CreateMyReferralView';
import KycReminder from '../kyc/KycReminder';
import useAutoRefreshData from 'src/hooks/useAutoRefreshData';
import useOnWindowResize from 'src/hooks/useOnWindowResize';
import { AuthContext } from '../../contexts/authContext';
import { createGesture, Gesture } from '@ionic/core';
import { WEB_BUILD_ENV } from 'src/libs/config';

type TProps = {
    children?: JSX.Element | JSX.Element[] | null;
};

const Layout: React.FunctionComponent<TProps> = (props: TProps) => {
    useAutoRefreshData();
    const bodyWidth = useOnWindowResize();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { isAdmin } = useContext(AuthContext);

    if (!WEB_BUILD_ENV) {
        const wrapperEl = document.querySelector('#wrapper');

        let gesture: Gesture | null = null;

        if (!!wrapperEl && !gesture) {
            gesture = createGesture({
                el: wrapperEl,
                threshold: 15,
                gestureName: 'my-gesture',
                onMove: (ev) => {
                    if (ev.deltaX > window.innerWidth / 3) {
                        setSidebarOpen(true);
                    }
                },
            });

            gesture.enable();
        }
    }

    return (
        <div id="wrapper" className="min-h-[100vh]">
            <SideDialogWrapper value={sidebarOpen} onClick={setSidebarOpen}>
                <SideBar />
            </SideDialogWrapper>
            {/* Static sidebar for desktop */}
            <div
                className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0"
                style={{ display: bodyWidth < 1440 ? 'none' : 'flex' }}
            >
                <SideBar />
            </div>
            <div
                className={clsx('flex flex-col', {
                    'pl-64': bodyWidth >= 1440,
                })}
            >
                <div className="sticky top-0 z-20 flex-shrink-0 flex h-16 bg-white shadow">
                    <button
                        type="button"
                        className={clsx(
                            'px-4 bg-blue sm:bg-blue lg:bg-white md:bg-white text-white',
                            'focus:outline-none onfocus-ring-blue',
                            {
                                hidden: bodyWidth >= 1440,
                            },
                        )}
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuAlt2Icon
                            className="h-6 w-6 text-white md:text-blue"
                            aria-hidden="true"
                        />
                    </button>
                    <div className="flex-1 px-4 flex justify-end bg-blue sm:bg-blue lg:bg-white md:bg-white">
                        <BarMenu />
                    </div>
                </div>

                {!isAdmin() && <KycReminder />}

                <main className="flex-1">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            {props.children}
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            {/* Replace with your content */}
                            <div className="py-4"></div>
                            {/* /End replace */}
                        </div>
                    </div>
                </main>
            </div>
            {!isAdmin() && <KycStatusFirstTimeDialog />}
            <CreateMyReferralView />
            <KycStatusDialog />
        </div>
    );
};

export default Layout;

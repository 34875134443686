import { useEffect, useState } from 'react';
import { LawyerAreaOfLaw } from 'src/libs/types/kyc';
import { normalizePhoneNumber, trimObjectValues } from 'src/libs/utils';
import { useDelete, useFetch, usePost, usePut } from './index';
import { ReferralStatus } from '../constants';
import { NEXT_PUBLIC_API_ENDPOINT } from 'src/libs/config';
import { TAccount } from './useAccount';

export type ReferralData = {
    areaOfLaw: LawyerAreaOfLaw;
    cityId: string;
    clientFirstName: string;
    clientLastName: string;
    country: string;
    state: string;
    description: string;
    email: string;
    percentage: number;
    phone: string;
    tags: string[];
    zip: string;
};

export type THistoryLogItem = {
    createdAt: string;
    comment: string;
    status: ReferralStatus;
};

export interface TMyReferralData extends ReferralData {
    accepted: TAccount;
    id: string;
    createdAt: string;
    updatedAt: string;
    acceptedAt: string;
    appointedAt: string;
    timeLeft: string;
    viewedCount: string;
    declinedCount: string;
    status: ReferralStatus;
    priceOfConsultation: number;
    log: THistoryLogItem[];
}

export interface TGlobalMetrics {
    totalActiveReferrals: number;
    totalClosedReferrals: number;
    totalLawyers: number;
    totalNewReferrals: number;
}

export type TMyCaseUpdateData = {
    status: string;
    comment?: string;
    priceOfConsultation?: string;
};

export enum ReferralActionType {
    DECLINED = 'DECLINED',
    ACCEPTED = 'ACCEPTED',
}

const serializeCreateReferralForm = (
    record: Record<string, any>,
): ReferralData => {
    return {
        areaOfLaw: record.areaOfLaw,
        cityId: record.cityId,
        clientFirstName: record.clientFirstName,
        clientLastName: record.clientLastName,
        country: record.country,
        state: record.state,
        description: record.description,
        email: record.email,
        percentage: record.percentage,
        phone: record.phone,
        tags: record.tags,
        zip: record.zip,
    };
};

export const useFetchReferral = (): [
    {
        data: Record<string | 'items', any | TMyReferralData[]>;
        error?: Record<string, any>;
    },
    Function,
    Boolean,
    Boolean,
] => {
    return useFetch<any>(`${NEXT_PUBLIC_API_ENDPOINT}/referrals/`, {});
};

export const useFetchMatchedReferrals = (): [
    {
        data: {
            count: number;
            items: TMyReferralData[];
            error?: any | undefined;
        };
        error?: Record<string, any>;
    },
    Function,
    Boolean,
    Boolean,
] => {
    return useFetch<any>(`${NEXT_PUBLIC_API_ENDPOINT}/referrals/`, {});
};

export const useFetchReferralById = (
    referralId: string,
): [
    { data: TMyReferralData; error?: Record<string, any> },
    Function,
    Boolean,
] => {
    const [{ data, error }, load, loading] = useFetch<any>(
        `${NEXT_PUBLIC_API_ENDPOINT}/referrals/${referralId}`,
        {},
    );
    const remapedData = { ...data, percentage: parseFloat(data.percentage) };
    return [{ data: remapedData, error }, load, loading];
};

export const useFetchAutoInfo = (): [
    { data: any; error?: Record<string, any> },
    Function,
    Boolean,
] => {
    const [{ data, error }, load, loading] = useFetch<any>(
        `${NEXT_PUBLIC_API_ENDPOINT}/metrics/auto`,
        {},
    );
    return [{ data, error }, load, loading];
};

export const useCreateReferral = (
    callback?: (val: { error?: any; data?: any }) => void,
): [Record<string, any>, Function, Boolean] => {
    const [data, postHandler, loading] = usePost(
        `${NEXT_PUBLIC_API_ENDPOINT}/referrals/`,
        callback,
    );

    const referralPostHandler = (postData: ReferralData) => {
        const phone = normalizePhoneNumber(postData.phone);
        postHandler(
            trimObjectValues(
                serializeCreateReferralForm({ ...postData, phone }),
            ),
        );
    };
    return [data, referralPostHandler, loading];
};

export const useUpdateReferral = (
    referralId: string,
    callback?: (val: { error?: any; data?: any }) => void,
): [Record<string, any>, Function, Boolean] => {
    const [data, postHandler, loading] = usePut(
        `${NEXT_PUBLIC_API_ENDPOINT}/referrals/${referralId}`,
        callback,
    );

    const referralPostHandler = (postData: ReferralData) =>
        postHandler(trimObjectValues(serializeCreateReferralForm(postData)));

    return [data, referralPostHandler, loading];
};

export const useConfirmReferral = (
    referralId: string,
    callback?: (val: { error?: any; data?: any }) => void,
): [Record<string, any>, Function, Boolean] => {
    const [data, confirmReferral, loading] = usePost(
        `${NEXT_PUBLIC_API_ENDPOINT}/referrals/${referralId}/action`,
        callback,
    );

    return [
        data,
        () => confirmReferral({ action: ReferralActionType.ACCEPTED }),
        loading,
    ];
};

export const useRejectReferral = (
    referralId: string,
    callback?: (val: { error?: any; data?: any }) => void,
): [Record<string, any>, Function, Boolean] => {
    const [data, rejectReferral, loading] = usePost(
        `${NEXT_PUBLIC_API_ENDPOINT}/referrals/${referralId}/action`,
        callback,
    );

    return [
        data,
        () => rejectReferral({ action: ReferralActionType.DECLINED }),
        loading,
    ];
};

export const useDeleteReferral = (
    referralId: string,
    callback?: (val: { error?: any; data?: any }) => void,
): [Record<string, any>, Function, Boolean] => {
    const [data, deleteRef, loading] = useDelete(
        `${NEXT_PUBLIC_API_ENDPOINT}/referrals/${referralId}`,
        callback,
    );

    return [data, () => deleteRef(), loading];
};

export const useFetchMyCases = (): [
    {
        data: Record<string | 'items', any | TMyReferralData[]>;
        error?: Record<string, any>;
    },
    Function,
    Boolean,
    Boolean,
] => {
    return useFetch<any>(`${NEXT_PUBLIC_API_ENDPOINT}/referrals/`, {});
};

export const useFetchGlobalMetrics = (): [
    {
        data: Record<any, TGlobalMetrics>;
        error?: Record<string, any>;
    },
    Function,
    Boolean,
    Boolean,
] => {
    return useFetch<any>(`${NEXT_PUBLIC_API_ENDPOINT}/metrics/all`, {});
};

export const useUpdateCaseStatus = (
    referralId: string,
    callback?: (val: { error?: any; data?: any }) => void,
): [Record<string, any>, Function, Boolean] => {
    const [data, postHandler, loading] = usePost(
        `${NEXT_PUBLIC_API_ENDPOINT}/referrals/${referralId}/status`,
        callback,
    );

    return [data, postHandler, loading];
};

export const useSignReferralAgreement = (
    referralId: string | null,
    callback?: (val: { error?: any; data?: any }) => void,
): [Record<string, any>, Function, Boolean] => {
    const [data, postHandler, loading] = usePost(
        `${NEXT_PUBLIC_API_ENDPOINT}/referrals/${referralId}/sign`,
        callback,
    );

    return [data, postHandler, loading];
};

export const useAcceptSignedReferralAgreement = (
    code: string | string[] | undefined,
    callback?: (val: { error?: any; data?: any }) => void,
): [Record<string, any>, Function, Boolean] => {
    const [data, postHandler, loading] = usePost(
        `${NEXT_PUBLIC_API_ENDPOINT}/referrals/sign/${code}`,
        callback,
    );

    return [data, postHandler, loading];
};

export const useMakePayment = (
    referralId: string,
    callback?: (val: { error?: any; data?: any }) => void,
): [Record<string, any>, Function, Boolean] => {
    return [
        {},
        () => {
            return 'aaa';
        },
        false,
    ];
};

export const useFetchTags = (): [Record<string, any>, Function, Boolean] => {
    const [response, load, loading] = useFetch<any>(
        `${NEXT_PUBLIC_API_ENDPOINT}/tags`,
        [],
    );
    const [remappedData, setRemappedData] = useState({
        data: [],
        error: undefined,
    });
    useEffect(() => {
        const remapped = response.data.items
            ? response.data.items.map((item: any) => item.usLocale)
            : [];
        setRemappedData({ data: remapped, error: response.error });
    }, [response]);

    return [remappedData, load, loading];
};

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

// @TODO: Add fn documentation
const useHashRoute = () => {
    const [hashRoute, setHashRoute] = useState('');
    const router = useRouter();

    useEffect(() => {
        setHashRouteWrapper(router.asPath);

        const onHashChangeStart = (url: string) => {
            setHashRouteWrapper(url);
        };

        router.events.on('hashChangeStart', onHashChangeStart);

        return () => router.events.off('hashChangeStart', onHashChangeStart);
    }, []);

    const setHashRouteWrapper = (url: string) => {
        const hash = url.split('#')[1] || '';
        setHashRoute(hash);
    };

    return hashRoute;
};

export default useHashRoute;

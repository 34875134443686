import {
    NotificationStatus,
    NotificationTopic,
    TNotification,
} from 'src/components/general/NotificationModal';

type TRawNotification = {
    id: string;
    userId: string;
    topic: NotificationTopic;
    status: NotificationStatus;
    message: string;
    seenAt: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    firstClickAt: string;
    deepLink: string;
};

export const serializeNotifications = (
    items: TRawNotification[],
): TNotification[] => {
    return items.map((item) => ({
        ...item,
        seenAt: item.seenAt ? new Date(item.seenAt) : null,
        createdAt: new Date(item.createdAt),
        updatedAt: item.updatedAt ? new Date(item.updatedAt) : null,
        deletedAt: item.deletedAt ? new Date(item.deletedAt) : null,
        firstClickAt: item.firstClickAt ? new Date(item.firstClickAt) : null,
    }));
};

import Link from 'next/link';
import { classNames } from 'src/libs/utils';
import { navigation, adminNavigation } from 'src/constants/navigation';
import { useRouter } from 'next/router';
import {
    PRIVACY_POLICY_PAGE_URL,
    TERMS_AND_CONDITIONS_PAGE_URL,
} from 'src/constants/links';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/authContext';

type TProps = {
    closeDialogMenu?: () => void;
};

export const SideBar = ({ closeDialogMenu }: TProps) => {
    const router = useRouter();
    const { asPath } = router;
    const [navigationList, setNavigationList] = useState(navigation);
    const { isAdmin } = useContext(AuthContext);

    useEffect(() => {
        if (isAdmin && isAdmin()) {
            setNavigationList(adminNavigation);
        }
    }, [isAdmin]);

    return (
        <div className="flex-1 flex flex-col min-h-0 bg-blue">
            <div className="flex items-center h-16 flex-shrink-0 px-4 bg-blue">
                <img
                    className="h-8 w-auto"
                    src="/images/LogoDashboard.svg"
                    alt="Workflow"
                />
            </div>
            <div className="flex-1 flex flex-col overflow-y-auto">
                <nav className="flex-1 px-2 py-4 space-y-1">
                    {navigationList.map((item, key) => (
                        <a
                            className={classNames(
                                asPath.includes(item.href)
                                    ? 'bg-activNav text-white'
                                    : 'text-gray-300 hover:bg-hoverNav hover:text-white',
                                'group flex items-center px-2 py-2 text-sm font-bold font-mont rounded-md',
                                'cursor-pointer',
                            )}
                            key={item.name}
                            onClick={() => {
                                closeDialogMenu && closeDialogMenu();
                                router.push(item.href);
                            }}
                        >
                            <item.icon
                                className={classNames(
                                    item.current
                                        ? 'text-gray-300'
                                        : 'text-gray-400 group-hover:text-gray-300',
                                    'mr-3 flex-shrink-0 h-6 w-6',
                                )}
                                aria-hidden="true"
                            />
                            {item.name}
                        </a>
                    ))}
                </nav>
            </div>
            <div className="flex flex-col relative bottom-3 pl-5">
                <Link href={TERMS_AND_CONDITIONS_PAGE_URL}>
                    <a className="mr-5 mb-2 whitespace-nowrap cursor-pointer text-xs font-mont text-gray-300">
                        Terms and conditions
                    </a>
                </Link>
                <Link href={PRIVACY_POLICY_PAGE_URL}>
                    <a className="whitespace-nowrap cursor-pointer text-xs font-mont text-gray-300">
                        Privacy policy
                    </a>
                </Link>
            </div>
        </div>
    );
};

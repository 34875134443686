import { ReactNode } from 'react';
import { BigGeneralSpinner } from 'src/components/common/spinner';
import ConfirmReferralIcon from 'assets/images/ConfirmReferralIcon.svg';

type TProps = {
    title: string;
    description: string;
    buttons?: ReactNode;
    showConfirmReferralIcon?: boolean;
};

const SideModalNotificator = ({
    title,
    description,
    buttons,
    showConfirmReferralIcon,
}: TProps) => {
    return (
        <div className="flex flex-col items-center justify-center px-[9.5rem] -my-4 min-h-[1050px] h-full">
            {/* <div className="pb-[5.625rem]"> */}
            <div className="pb-[2.625rem]">
                <img src="/images/Logo.svg" />
            </div>
            {/* <div className="text-3xl font-bold pb-[5.625rem]">{title}</div> */}
            <div className="text-3xl font-bold pb-[2.625rem]">{title}</div>
            {/* <div className="pb-[7.5rem]"> */}
            <div className="pb-[2.5rem]">
                {showConfirmReferralIcon ? (
                    <ConfirmReferralIcon style={{ transform: 'scale(0.8)' }} />
                ) : (
                    <BigGeneralSpinner />
                )}
            </div>
            <div
                className="font-mont text-xl font-normal text-center"
                style={showConfirmReferralIcon ? { width: '30rem' } : {}}
            >
                {description}
            </div>
            <div>{buttons}</div>
        </div>
    );
};

export default SideModalNotificator;

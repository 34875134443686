import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import {
    TMyReferralData,
    useFetchAutoInfo,
    useFetchGlobalMetrics,
} from 'src/services/useReferral';
import { AuthContext, AuthStatus, IAuth } from 'src/contexts/authContext';
import {
    updateMissedReferrals,
    updateCurrentCountAvailableReferrals,
} from 'src/store/slices/notificationsSlice';

let interval: any = null;

const useAutoRefreshData = () => {
    const dispatch = useDispatch();
    const [{ data }, loadData] = useFetchAutoInfo();
    const { authStatus, userSession }: IAuth = useContext(AuthContext);

    const userId = userSession?.userData.id || '';

    const debouncedFetchAccountData = debounce(() => {
        if (interval) {
            clearTimeout(interval);
        }

        interval = setInterval(async () => {
            await loadData();
        }, 15000);
    }, 18000);

    useEffect(() => {
        if (authStatus !== AuthStatus.SignedIn) {
            clearTimeout(interval);
        }
    }, [authStatus]);

    useEffect(() => {
        userId && debouncedFetchAccountData();
    }, [userId]);

    useEffect(() => {
        if (data.count) {
            dispatch(updateCurrentCountAvailableReferrals(data.count));
        }
        if (data.missedReferrals) {
            dispatch(updateMissedReferrals(data.missedReferrals));
        }
    }, [data]);
};

export default useAutoRefreshData;

import { useFetch, usePatch } from '.';
import { NEXT_PUBLIC_API_ENDPOINT } from 'src/libs/config';
import useInterval from 'use-interval';
import { useEffect, useMemo, useState } from 'react';
import { TNotification } from 'src/components/general/NotificationModal';
import { serializeNotifications } from './utils';

export type TNotificationCountResponse = {
    count: number;
};
/**
 * Ask every 5 seconds for the number of notifications
 * @TODO: Only Ask if user is logged in
 */
export const useFetchNotificationCount = (): [number, boolean] => {
    const [{ data }, load, loading] = useFetch<{ count: number }>(
        `${NEXT_PUBLIC_API_ENDPOINT}/notifications/count`,
        { count: 0 },
        false,
    );
    const [count, setCount] = useState(0);
    useInterval(() => load(), 3000, true);

    useMemo(() => {
        setCount(data?.count || 0);
    }, [data?.count]);

    return [count, loading];
};

/**
 * Get notification list
 * @TODO: Only Ask if user is logged in
 * @TODO: Only ask for notifications updated since last time
 */
export const useFetchNotifications = (): [
    TNotification[],
    Function,
    boolean,
] => {
    const [{ data }, load, loading] = useFetch<any>(
        `${NEXT_PUBLIC_API_ENDPOINT}/notifications`,
        {},
        false,
    );
    const serialized = serializeNotifications(data?.items || []);
    return [serialized, load, loading];
};

export const useMarkAllNotificationsAsClicked = (): Function => {
    const [_, handler] = usePatch<void>(
        `${NEXT_PUBLIC_API_ENDPOINT}/notifications/click-all`,
    );
    return handler;
};

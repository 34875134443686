import router from 'next/router';
import { useContext, useEffect, useState } from 'react';

import SideModal from 'src/components/common/modals/SideModal';
import { MY_REFERRALS_PAGE_URL, SEND_REFERRAL_HASH } from 'src/constants/links';
import { KycStatus, KycStatusContext } from 'src/contexts/kycStatusContext';
import useHashRoute from 'src/hooks/useHashRoute';
import { useCreateReferral } from 'src/services/useReferral';
import SideModalNotificator from '../general/SideModalNotificator';
import MyReferralForm from './MyReferralForm';

const CreateMyReferralView = () => {
    const hashRoute = useHashRoute();
    const [modalShouldShown, setModalShouldShown] = useState(false);
    const { kycData } = useContext(KycStatusContext);
    const [, postHandler, loading] = useCreateReferral(({ error }: any) => {
        if (!error) {
            onSuccess();
        }
    });

    const onSubmitHandler = (data: any) => {
        postHandler(data);
    };

    const onSuccess = () => {
        if (router.asPath === MY_REFERRALS_PAGE_URL) {
            const newPath = router.asPath.replace(`#${SEND_REFERRAL_HASH}`, '');
            router.push(newPath);
        } else {
            router.push(MY_REFERRALS_PAGE_URL);
        }
    };

    const onClose = () => {
        if (modalShouldShown) {
            const newPath = router.asPath.replace(`#${SEND_REFERRAL_HASH}`, '');
            router.push(newPath);
        }
    };

    useEffect(() => {
        if (
            hashRoute === SEND_REFERRAL_HASH &&
            kycData.status === KycStatus.VERIFIED
        )
            setModalShouldShown(true);
        if (!hashRoute) setModalShouldShown(false);
    }, [hashRoute]);

    return (
        <div>
            <SideModal
                open={modalShouldShown}
                onClose={onClose}
                headerLabel={!loading ? 'Send a referral' : ''}
            >
                {!loading ? (
                    <div className="pb-[70px] pl-1">
                        <MyReferralForm
                            onSubmitCallback={onSubmitHandler}
                            onCloseCallback={onClose}
                        />
                    </div>
                ) : (
                    <SideModalNotificator
                        title="Send a Referral..."
                        description="Please do not close the tab, we're creating and posting your referral on a platform, to all lawyers..."
                    />
                )}
            </SideModal>
        </div>
    );
};

export default CreateMyReferralView;

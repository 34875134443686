import React, { useContext, useState } from 'react';
import { useRouter } from 'next/router';
import { AuthContext } from 'src/contexts/authContext';
import { AccountContext } from 'src/contexts/accountContext';
import { ModalDialog } from '../common/modals';

import SignOutIcon from 'assets/images/signout.svg';
import SignOutDialogIcon from 'assets/images/signOutDialogIcon.svg';
import { LOGIN_PAGE_URL } from 'src/constants/links';
import { KycStatusContext } from 'src/contexts/kycStatusContext';

const SignOut: React.FunctionComponent = () => {
    const router = useRouter();
    const { signOut } = useContext(AuthContext);
    const { kycData } = useContext(KycStatusContext);
    const { accountData } = useContext(AccountContext);
    const [logoutModalShouldShow, setLogoutModalShouldShow] = useState(false);

    const onCancel = () => {
        setLogoutModalShouldShow(false);
    };

    const onLogout = () => {
        signOut(() => {
            router.push(LOGIN_PAGE_URL);
        });
    };

    const firstName = kycData.firstName
        ? kycData.firstName
        : accountData.firstName || '';
    const lastName = kycData.lastName
        ? kycData.lastName
        : accountData.lastName || '';

    return (
        <>
            <button
                type="button"
                className="sign-out ml-2 bg-blue sm:bg-blue lg:bg-white md:bg-white p-1 rounded-full text-white lg:text-black md:text-black hover:text-gray-500 focus:outline-none onfocus-ring-blue"
                onClick={() => setLogoutModalShouldShow(true)}
                name="logout"
            >
                <SignOutIcon className="w-[24px] h-[24px]" strokeWidth={2} />
            </button>

            <ModalDialog
                open={logoutModalShouldShow}
                onClose={() => {}}
                icon={<SignOutDialogIcon />}
                title="Log out?"
                description={`Dear ${
                    firstName || lastName
                        ? firstName + ' ' + lastName
                        : 'Customer'
                }, do you want to end the active session and log access to your account? If yes, you have to enter again your credential to get access to the platform.`}
                primaryButton={{
                    label: 'Logout',
                    handler: onLogout,
                }}
                outlinedButton={{
                    label: 'Cancel',
                    handler: onCancel,
                }}
            />
        </>
    );
};

export default SignOut;

import { Dialog } from '@headlessui/react';
import { GeneralSpinner } from '../spinner';
import GeneralModal from './GeneralModal';

type TProps = {
    description?: string;
    open: boolean;
    onClose: () => void;
};

const ModalLoader = ({ description, open, onClose }: TProps) => {
    return (
        <GeneralModal open={open} onClose={onClose}>
            <div className="flex justify-center align-center flex-col w-50">
                <div className="flex justify-around p-10">
                    <GeneralSpinner />
                </div>
                <div className="flex justify-around text-center px-5 md:px-20 text-sm font-bold text-black">
                    {description ||
                        'Please don’t close the tab we’re processing now...'}
                </div>
            </div>
        </GeneralModal>
    );
};

export default ModalLoader;

import clsx from 'clsx';

type TProps = {
    children: JSX.Element | JSX.Element[] | null;
    className?: string;
    flexRow?: boolean;
};

const Row: React.FunctionComponent<TProps> = ({
    children,
    flexRow,
    className,
}: TProps) => (
    <div
        className={clsx(
            'row flex w-full sm:flex-row',
            !flexRow ? 'flex-col' : 'flex-row',
            className,
        )}
    >
        {children}
    </div>
);

export default Row;

import { useRouter } from 'next/router';
import { ModalDialog } from '../common/modals';
import KycDialogIcon from 'assets/images/kycDialogIcon.svg';

import { KycStatus, KycStatusContext } from '../../contexts/kycStatusContext';

import { KYC_PAGE_URL, SEND_REFERRAL_HASH } from 'src/constants/links';
import { useContext, useEffect, useState } from 'react';
import useHashRoute from 'src/hooks/useHashRoute';
import { AccountContext } from 'src/contexts/accountContext';
import { TKycData } from 'src/services/useKyc';
import { TAccount } from 'src/services/useAccount';

type TProps = {
    firstName: string;
    lastName: string;
    open?: boolean;
    hideDialog?: () => void;
};

const HIDE_KYC_STATUSES = [
    KycStatus.VERIFIED,
    KycStatus.ON_VERIFICATION,
    KycStatus.UNVERIFIED,
];

const generateCustomerName = (
    kycData: TKycData | { [key: string]: any },
    accountData: TAccount,
) => {
    const firstName = kycData.firstName
        ? kycData.firstName
        : accountData.firstName || '';
    const lastName = kycData.lastName
        ? kycData.lastName
        : accountData.lastName || '';

    return { firstName, lastName };
};

export const KycStatusDialogBody: React.FunctionComponent<TProps> = ({
    firstName,
    lastName,
    open,
    hideDialog,
}: TProps) => {
    const router = useRouter();

    const onCancel = () => {
        hideDialog && hideDialog();
    };

    const onContinue = () => {
        router.push(KYC_PAGE_URL);
    };

    const isOpen = typeof open === 'boolean' ? open : true;
    return (
        <ModalDialog
            open={isOpen}
            onClose={() => {}}
            icon={<KycDialogIcon />}
            title="Provide KYC details"
            description={`Dear ${
                firstName || lastName ? firstName + ' ' + lastName : 'Customer'
            }, please fill KYC application based on your type of use of the platform, as a personal lawyer or company form. To continue using the platform with full access.`}
            primaryButton={{
                label: 'Go to KYC process',
                handler: onContinue,
            }}
            outlinedButton={{
                label: 'Cancel',
                handler: onCancel,
            }}
        />
    );
};

const KycStatusFirstTimeDialog = () => {
    const [shouldShowDialog, setShouldShowDialog] = useState(false);
    const { kycData, shouldHideFirstTimeDialog, hideFirstTimeDialog } =
        useContext(KycStatusContext);
    const { accountData } = useContext(AccountContext);

    const { firstName, lastName } = generateCustomerName(kycData, accountData);

    useEffect(() => {
        if (kycData.status !== KycStatus.LOADING) {
            setShouldShowDialog(
                !shouldHideFirstTimeDialog &&
                    !HIDE_KYC_STATUSES.includes(kycData.status),
            );
        }
    }, [shouldHideFirstTimeDialog, kycData]);

    return shouldShowDialog ? (
        <KycStatusDialogBody
            firstName={firstName}
            lastName={lastName}
            hideDialog={hideFirstTimeDialog}
        />
    ) : null;
};

export const KycStatusDialog = () => {
    const router = useRouter();
    const hashRoute = useHashRoute();
    const { kycData, shouldShowDialog, showDialog, hideDialog } =
        useContext(KycStatusContext);
    const { accountData } = useContext(AccountContext);

    const { firstName, lastName } = generateCustomerName(kycData, accountData);

    useEffect(() => {
        if (
            hashRoute === SEND_REFERRAL_HASH &&
            kycData.status !== KycStatus.VERIFIED
        ) {
            showDialog && showDialog();
        }
    }, [hashRoute, kycData]);

    const onClose = () => {
        if (hashRoute === SEND_REFERRAL_HASH) {
            const newPath = router.asPath.replace(`#${SEND_REFERRAL_HASH}`, '');
            router.push(newPath);
        }
        hideDialog && hideDialog();
    };
    return (
        <KycStatusDialogBody
            firstName={firstName}
            lastName={lastName}
            open={shouldShowDialog}
            hideDialog={onClose}
        />
    );
};

export default KycStatusFirstTimeDialog;

import { useEffect, useState } from 'react';

const useOnWindowResize = () => {
    const [bodyWidth, setBodyWidth] = useState(document?.body.offsetWidth);
    useEffect(() => {
        const onResizeEvent = () => {
            setBodyWidth(document?.body.offsetWidth);
        };
        window.addEventListener('resize', onResizeEvent, true);

        return () => {
            window.removeEventListener('resize', onResizeEvent, true);
        };
    }, []);

    return bodyWidth;
};

export default useOnWindowResize;

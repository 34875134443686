import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
    Checkbox,
    InputLabel,
    NumberInput,
    PhoneInput,
    SelectInput,
    Textarea,
    TextInput,
} from 'src/components/common/form/input';
import Row from 'src/components/common/grid/Row';
import Field from 'src/components/common/grid/Field';
import TagsInput from 'src/components/common/form/tags';
import { areaOfLawList, TStateAndCountryList } from 'src/constants/kycLists';
import { KycStatusContext } from 'src/contexts/kycStatusContext';
import { statesAndProvinces } from 'src/constants/kycLists';
import { referralValidationRules } from 'src/libs/validation';
import SideModalFooter from 'src/components/common/modals/SideModal/SideModalFooter';
import OutlinedButton from 'src/components/common/buttons/OutlinedButton';
import { PrimaryButton } from 'src/components/common/buttons/PrimaryButton';
import { TMyReferralData } from 'src/services/useReferral';

interface IFormData extends TMyReferralData {
    agreement?: boolean;
}

type TProps = {
    data?: IFormData;
    onSubmitCallback: (data: any) => void;
    onCloseCallback: () => void;
};

const SendReferral = ({ data, onSubmitCallback, onCloseCallback }: TProps) => {
    const [filteredStatesAndProvinces, setFilteredStatesAndProvinces] =
        useState<TStateAndCountryList[]>([]);

    const { kycData } = useContext(KycStatusContext);

    useEffect(() => {
        let filtered = [{ label: 'Loading...', value: '' }];
        if (kycData?.country) {
            //For testing by ua number phone
            const country = kycData.country === 'UA' ? 'CA' : kycData.country;
            filtered = statesAndProvinces.filter(
                (item) => item.countryId === country,
            );
        }
        setFilteredStatesAndProvinces(filtered);
    }, [kycData]);

    const {
        register,
        handleSubmit,
        watch,
        control,
        reset,
        formState: { errors, isValid },
    } = useForm({
        mode: 'all',
        defaultValues: useMemo(() => data, [data]),
    });

    watch();

    useEffect(() => {
        //FYI using is workaround for proper validation work. PRD-588
        reset(data || {});
    }, [data]);

    const onCancel = () => {
        onCloseCallback();
    };

    return (
        <form
            onSubmit={handleSubmit((data) =>
                onSubmitCallback({ ...data, country: kycData.country }),
            )}
        >
            <div className="flex flex-col md:pb-0">
                <Row className="pb-1.5">
                    <p className="font-mont text-normal font-bold text-black-700 mt-4">
                        Client
                    </p>
                </Row>

                <Row>
                    <Field className="pr-0 sm:pr-5">
                        <InputLabel htmlFor="clientFirstName">
                            First name
                        </InputLabel>
                        <div className="mt-1">
                            <TextInput
                                autoComplete="on"
                                className="kyc-text-field"
                                placeholder="Enter..."
                                registerReactHookInput={register(
                                    'clientFirstName',
                                    referralValidationRules.firstName,
                                )}
                                error={errors.clientFirstName}
                            />
                        </div>
                    </Field>

                    <Field>
                        <InputLabel htmlFor="clientLastName">
                            Last name
                        </InputLabel>
                        <div className="mt-1">
                            <TextInput
                                autoComplete="on"
                                className="kyc-text-field"
                                placeholder="Enter..."
                                registerReactHookInput={register(
                                    'clientLastName',
                                    referralValidationRules.lastName,
                                )}
                                error={errors.clientLastName}
                            />
                        </div>
                    </Field>
                </Row>

                <Row className="pb-1.5">
                    <p className="font-mont text-normal font-bold text-black-700 mt-4">
                        Type of practice
                    </p>
                </Row>

                <Row>
                    <div className="relative flex w-full">
                        <Controller
                            name="areaOfLaw"
                            control={control}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <SelectInput
                                    {...register(
                                        'areaOfLaw',
                                        referralValidationRules.areaOfLaw,
                                    )}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    value={value}
                                    options={areaOfLawList}
                                />
                            )}
                        />
                    </div>
                </Row>

                <Row className="pb-1.5">
                    <p className="font-mont text-normal font-bold text-black-700 mt-4">
                        Location
                    </p>
                </Row>

                <Row className="pb-0 sm:pb-5">
                    <Field className="pr-0 sm:pr-5">
                        <InputLabel htmlFor="state">State/Province</InputLabel>
                        <div className="mt-1 relative flex">
                            <Controller
                                name="state"
                                control={control}
                                render={({
                                    field: { onChange, onBlur, value },
                                }) => (
                                    <SelectInput
                                        {...register(
                                            'state',
                                            referralValidationRules.state,
                                        )}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        options={filteredStatesAndProvinces}
                                    />
                                )}
                            />
                        </div>
                    </Field>

                    <Field>
                        <InputLabel htmlFor="city">City</InputLabel>
                        <div className="mt-1">
                            <TextInput
                                autoComplete="on"
                                className="kyc-text-field"
                                placeholder="Enter..."
                                registerReactHookInput={register(
                                    'cityId',
                                    referralValidationRules.cityId,
                                )}
                                error={errors.cityId}
                            />
                        </div>
                    </Field>
                </Row>

                <Row>
                    <Field className="pr-0 sm:pr-5">
                        <InputLabel htmlFor="zip">ZIP/Postal code</InputLabel>
                        <div className="mt-1">
                            <TextInput
                                autoComplete="on"
                                className="kyc-text-field"
                                placeholder="Enter..."
                                registerReactHookInput={register(
                                    'zip',
                                    referralValidationRules.zip,
                                )}
                                error={errors.zip}
                            />
                        </div>
                    </Field>
                </Row>

                <Row className="pb-1.5">
                    <p className="font-mont text-normal font-bold text-black-700 mt-4">
                        Contacts
                    </p>
                </Row>

                <Row>
                    <Field className="pr-0 sm:pr-5">
                        <InputLabel htmlFor="phone">Phone</InputLabel>
                        <div className="mt-1">
                            <PhoneInput
                                mask="+1(999)999-9999"
                                className="kyc-text-field"
                                placeholder="Enter..."
                                registerReactHookInput={register(
                                    'phone',
                                    referralValidationRules.phone,
                                )}
                                error={errors.phone}
                            />
                        </div>
                    </Field>

                    <Field>
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <div className="mt-1">
                            <TextInput
                                autoComplete="on"
                                className="kyc-text-field"
                                placeholder="Enter..."
                                registerReactHookInput={register(
                                    'email',
                                    referralValidationRules.email,
                                )}
                                error={errors.email}
                            />
                        </div>
                    </Field>
                </Row>

                <Row className="pb-2">
                    <p className="font-mont text-normal font-bold text-black-700 mt-4">
                        Description
                    </p>
                </Row>

                <Row>
                    <Field className="min-w-[100%]">
                        <Textarea
                            placeholder="Enter..."
                            className="kyc-text-field h-[200px] resize-none w-full"
                            registerReactHookInput={register(
                                'description',
                                referralValidationRules.description,
                            )}
                            error={errors.description}
                        />
                    </Field>
                </Row>

                {/* PRD-702 hide TAGS */}
                {/* <div className="flex w-full">
                    <p className="font-mont text-normal font-bold text-black-700 mt-4">
                        Tags
                    </p>
                </div>

                <div className="w-full">
                    <div className="h-[100px]">
                        <Controller
                            name="tags"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TagsInput
                                    registerReactHookInput={register(
                                        'tags',
                                        referralValidationRules.tags,
                                    )}
                                    placeholder="Enter..."
                                    className="kyc-text-field"
                                    values={value}
                                    onChange={onChange}
                                    error={errors.tags}
                                />
                            )}
                        />
                    </div>
                </div> */}

                <Row className="mb-1">
                    <p className="font-mont text-normal font-bold text-black-700 mt-4">
                        Referral %
                    </p>
                </Row>

                <Row className="items-center mb-4">
                    <Field>
                        <div className="mt-1">
                            <NumberInput
                                autoComplete="on"
                                className="kyc-text-field no-spin-button"
                                placeholder="Enter..."
                                min={0}
                                max={50}
                                registerReactHookInput={register(
                                    'percentage',
                                    referralValidationRules.percentage,
                                )}
                                error={errors.percentage}
                                step="0.01"
                            />
                        </div>
                    </Field>

                    {/* <div className="md:w-1/2 text-grayRadioBtn text-sm">
                        Some additional text information about the referral and
                        other info.
                    </div> */}
                </Row>

                <Row>
                    <div className="flex flex-row">
                        <div className="flex-1 md:mr-[20px] mb-5 md:mb-0 ">
                            <div className="md:mt-1 mr-2 md:mr-0">
                                <Checkbox
                                    registerReactHookInput={register(
                                        'agreement',
                                        referralValidationRules.agreement,
                                    )}
                                    hideErrorMsg
                                    error={errors.agreement}
                                />
                            </div>
                        </div>

                        <div className="">
                            By clicking submit, I confirm that the referral
                            above has provided their consent to share their
                            personal information with other lawyers
                        </div>
                    </div>
                    <span className="font-mont text-redErr mb-8 mt-2 text-sm w-[30rem]">
                        {typeof errors.agreement === 'object'
                            ? errors.agreement?.message
                            : errors.agreement}
                    </span>
                </Row>
            </div>
            <SideModalFooter>
                <OutlinedButton
                    type="button"
                    label="Cancel"
                    onClick={onCancel}
                    margin="mb-0 mr-[30px]"
                    paddingY="py-3"
                    className="w-1/2"
                />
                <PrimaryButton
                    label="Submit"
                    disabled={!isValid}
                    paddingX="px-[2rem]"
                    paddingY="py-3"
                    margin="mb-0 md:mr-[20px]"
                    className="w-1/2"
                />
            </SideModalFooter>
        </form>
    );
};

export default SendReferral;

import React from 'react';
import clsx from 'clsx';
import { ButtonProps } from 'src/libs/types/forms';

export const OutlinedButton = React.forwardRef(function MainButton(
    props: ButtonProps,
    ref?: React.LegacyRef<HTMLButtonElement>,
) {
    const {
        fontWeight,
        textColor,
        textSize,
        paddingX,
        paddingY,
        margin,
        label,
        type,
        width,
        bgColor,
        children,
        ...otherProps
    } = props;
    return (
        <button
            type={type || 'submit'}
            {...otherProps}
            className={clsx(
                fontWeight || 'font-bold',
                textColor || 'text-blue disabled:text-white',
                textSize || 'text-sm',
                paddingX || 'px-[26px]',
                paddingY || 'py-3.5',
                margin || 'mb-[2.0625rem]',
                bgColor ||
                    'bg-outlinedBtnBg hover:bg-outlinedBtnBgHov disabled:bg-outlinedBtnBgDisabled',
                width,
                'font-mont whitespace-nowrap leading-[17px] focus:outline-none onfocus-ring-blue',
                'border-2 border-[#1F2E56] disabled:bg-outlinedBtnBgDisabled',
                'flex items-center justify-center rounded-md shadow-sm cursor-pointer',
                props.className || '',
            )}
            style={{ border: '2px solid' }}
            ref={ref}
        >
            {label || children}
        </button>
    );
});

export default OutlinedButton;
